@use "src/styles/colors";
@use "src/styles/fonts";

.media-card {
  display: flex;
  flex-direction: column;
  border-radius: 0;
  background-color: colors.$transparent;

  & > &:first-child {
    border-radius: 10px;
  }

  & > *:nth-child(1n) {
    width: 100%;
    padding: 0;
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: colors.$lavender1;
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    position: relative;
    box-shadow: none;

    .plus {
      width: theme("spacing.9");
      height: theme("spacing.9");

      path {
        fill: colors.$purple6;
      }
    }

    .broken {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: theme("colors.grey");
      border-radius: 10px;

      svg {
        @apply w-10 h-10 stroke-light-blue-steel;
      }
    }

    img,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  .loading {
    position: absolute;
    background-color: colors.$lavender1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .actions {
    display: flex;
    position: absolute;
    right: theme("spacing.4");
    top: theme("spacing.4");

    &-item {
      margin: auto;
      margin-left: theme("spacing.4");
      background-color: colors.$white;

      svg {
        @apply w-7 h-7;

        path {
          stroke: theme("colors.blue-steel");
        }
      }

      &.add-version {
        background-color: theme("colors.blue-steel");

        svg path {
          stroke: colors.$white;
        }
      }
    }
  }

  .metadata {
    @include fonts.little;
    margin-top: theme("spacing.5");

    .name {
      @apply text-sm text-blackberry font-medium;
      margin-bottom: theme("spacing.3");
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.clickable {
        cursor: pointer;
      }
    }

    .details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: theme("spacing.3");
      text-transform: uppercase;
      font-weight: fonts.$medium;

      .size {
        @apply text-xs text-blue-steel;

        span {
          @apply text-red;
          text-transform: uppercase;
          margin-left: 0.25rem;
        }
      }

      .info span {
        @apply text-xss text-blackberry bg-grey py-2 px-4;
        border-radius: theme("spacing.3");

        &.version {
          @apply bg-light-teal text-blackberry mr-4;
        }
      }
    }
  }

  .progress {
    & > div {
      transition: width 3s;
      animation-duration: 3s;
    }
  }

  .caption {
    padding: theme("spacing.4") theme("spacing.5");

    .progress {
      margin-top: theme("spacing.5");
      margin-bottom: 13px;
    }

    textarea {
      resize: none;
      @apply text-base text-blackberry;
      padding: 0 0 0 theme("spacing.8");
      box-shadow: none;
      border-radius: 0;
      width: 100%;

      &::placeholder {
        @apply text-base text-light-blue-steel;
      }

      &:focus {
        outline: none;
      }

      & ~ div:last-child {
        margin-top: theme("spacing.2");
        min-width: unset;
        padding: 0;
      }
    }
  }

  .selection {
    z-index: 1;
    position: absolute;
    left: 15px;
    top: 15px;
    display: block;
    width: auto;
    height: auto;
    opacity: 0;
    transition: opacity 0.1s linear;
    border-radius: 50%;

    svg path {
      stroke-width: 1.5;
    }

    &.selected {
      opacity: 1;

      svg path {
        @apply fill-teal;
      }
    }
  }

  &:hover {
    .selection {
      opacity: 1;
    }
  }
}
