.button {
  @apply text-sm font-medium py-5 px-6;

  border-width: 0;
  background-color: transparent !important;

  svg {
    path {
      stroke: theme('colors.teal') !important;
    }
  }
}