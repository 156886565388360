.container {
  @apply py-5 flex items-center text-sm font-medium;

  &.active,
  &:hover {
    @apply text-blackberry font-bold;

    path {
      stroke: theme("colors.blackberry");
    }
  }

  &.default {
    @apply text-blue-steel;
  }

  &.sub-menu {
    @apply font-medium text-light-blue-steel;

    &.active,
    &:hover {
      @apply text-blackberry;

      path {
        stroke: theme("colors.blackberry");
      }
    }
  }

  .icon {
    width: 24px;
    min-width: 24px;
    height: 16px;

    svg {
      width: 100%;
      height: 100%;
      stroke-width: 1.5px;
    }
  }
}
