@use "src/styles/colors";
@use "src/styles/fonts";

.color-picker {
  position: relative;
  display: inline-flex;
  flex-direction: column;

  &-area {
    position: absolute;
    bottom: 80px;

    div[class="rcp-body"] {
      border-right: theme("spacing.2") solid theme("colors.grey");
      border-left: theme("spacing.2") solid theme("colors.grey");
    }
  }

  &-view {
    height: 72px;

    &:hover {
      .remove-button[type="button"] {
        opacity: 1;
      }
    }

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: theme("spacing.10");
      border-radius: theme("spacing.4");

      svg {
        @apply w-7 h-7;

        path {
          stroke: colors.$white;
        }
      }
    }

    &-title {
      @apply text-sm mt-4 text-center;
    }

    .remove-button[type="button"] {
      opacity: 0;
      transition: opacity 0.2s linear;
      position: absolute;
      position: absolute;
      padding: 5px;
      right: calc(theme("spacing.3") * -1);
      top: calc(theme("spacing.4") * -1);
      background-color: colors.$white;

      svg {
        @apply w-7 h-7 stroke-red;
      }
    }
  }

  &-actions {
    display: flex;
    background: colors.$white;
    border: theme("spacing.2") solid theme("colors.grey");
    border-top: none;
    padding: 0 20px 20px;
    border-radius: 10px;
    margin-top: calc(theme("spacing.4") * -1);

    button {
      width: 50%;
    }
  }
}
