@use "src/styles/colors";

.saveButton {
  border-color: colors.$green3;
  border: 1px solid red;
  background-color: colors.$green3;

  &.saved {
    background-color: colors.$green3;
    color: colors.$green8;

    &:not(:disabled) {
      color: colors.$green8;
    }

    svg > path {
      stroke: colors.$green8;
    }
  }

  &:not(.saved):disabled {
    &,
    &:hover {
      background-color: colors.$transparent;
      border-color: colors.$transparent;
      color: colors.$grey6;
    }
  }
}
