.fieldset {
  @apply flex flex-col mb-8;
}

.label {
  @apply text-l text-blackberry font-bold mb-6;
}

.info {
  @apply flex text-l text-blue-steel gap-3;

  &-title {
    @apply font-bold text-blackberry;
  }
}

.error {
  @apply absolute text-xs text-red bottom-4;
}

.password {
  @apply w-full;

  svg path {
    stroke: theme("colors.blue-steel") !important;
  }
}
