@use "src/styles/colors";

.country {
  &-popover {
    width: 100%;
    max-height: 300px;
    overflow: auto;
    cursor: pointer;
    padding: theme("spacing.5") 0;

    .item {
      padding: 0;

      &:hover {
        background-color: colors.$transparent;
      }

      .region {
        display: flex;
        align-items: stretch;
        transition: color 0.2s ease;

        &:hover,
        &.active .region-title {
          @apply text-blackberry font-bold;
        }

        &:not(.multiselect):hover {
          .region-arrow {
            stroke-width: 2px;
            svg {
              @apply w-7 h-7;
            }
          }
        }

        &-arrow {
          width: auto;
          padding: 0 theme("spacing.3");
          transition: transform 0.2s ease;
          transform: rotate(-90deg);
          margin-left: theme("spacing.4");

          &:hover {
            stroke-width: 2px;
          }

          &.active {
            transform: rotate(0deg);
          }
        }

        &-title {
          @apply font-medium text-blue-steel;
          display: flex;
          width: 100%;
          padding: 10px 0 10px theme("spacing.3");

          &:hover {
            @apply text-blackberry font-bold;
          }
        }
      }

      .countries {
        display: none;
        flex-direction: column;

        &.visible {
          display: flex;
        }

        .country {
          padding: theme("spacing.3") 0 theme("spacing.3") theme("spacing.9");
          color: theme("colors.light-blue-steel");
          transition: color 0.2s ease;

          &:hover,
          &.selected {
            @apply text-blackberry font-bold;
          }
        }
      }
    }

    .search {
      display: block;
      margin: theme("spacing.5") theme("spacing.6");
      width: auto;

      input {
        padding: theme("spacing.5") theme("spacing.7") theme("spacing.5")
          theme("spacing.10");

        &::placeholder {
          @apply font-sm;
        }
      }

      svg {
        margin-bottom: theme("spacing.3");
      }
    }
  }
}
