@use "src/styles/colors";
@use "src/styles/fonts";

.button {
  @apply py-4 px-5;
  border-radius: theme("spacing.4");

  svg path {
    transition: all 0.25s ease 0s;
  }

  &.active {
    &,
    &:hover,
    &:focus {
      @apply bg-mint text-teal;

      svg path {
        @apply stroke-teal;
      }
    }
  }
}

.popover {
  @apply py-6 pl-8 pr-7;

  .filter {
    @apply grid grid-flow-col gap-4 grid-cols-[145px,295px,36px] items-center;
  }

  .dropdown {
    &,
    &:hover,
    &:focus {
      @apply text-left bg-white border-0 shadow-[0_0_0_1px] shadow-light-blue-steel text-blue-steel h-full;

      &.empty {
        @apply text-mid-blue-steel;
      }

      span {
        display: none;
      }

      svg {
        path {
          stroke: theme("colors.blue-steel");
        }
      }
    }

    &.date {
      @apply pr-6 py-5 text-light-blue-steel font-regular pl-6;

      &-selected p {
        @apply font-sm;
        display: flex;
        align-items: center;
        background: theme("colors.mint");
        color: theme("colors.teal");
        border-radius: theme("spacing.5");
        margin-right: theme("spacing.4");
        padding: theme("spacing.2") theme("spacing.4");
      }
    }
  }

  .multi-input {
    @apply px-6 py-5;
    box-shadow: 0 0 0 theme("spacing.1") theme("colors.light-blue-steel");

    & > svg {
      margin: 0;
    }
  }

  .search {
    width: 240px;
    display: block;
    margin: theme("spacing.5") auto theme("spacing.4");

    input,
    div[class*="input__icon"] {
      height: 35px;

      svg {
        margin: 0;
      }
    }
  }

  .action {
    &-button {
      &,
      &:hover,
      &:focus {
        @apply text-blue-steel;

        svg {
          @apply w-7 h-7;

          path {
            stroke: theme("colors.blue-steel");
          }
        }
      }
    }

    &-remove {
      aspect-ratio: 1/1;

      svg {
        @apply w-7 h-7;

        path {
          stroke: theme("colors.blue-steel");
        }
      }

      &:hover {
        svg path {
          stroke: theme("colors.red");
        }
      }
    }
  }
}

.checkbox {
  @apply grid grid-flow-col place-self-start;
  height: 20px;
}

.datepicker-wrapper {
  position: absolute;
  left: 104px;
  top: -5px;

  // workaround to display the date picker outside the parent element with overflow: auto
  & + div[class="react-datepicker__tab-loop"] {
    position: fixed;
  }
}

.datepicker {
  @apply rounded-lg border border-light-blue-steel text-xs;

  width: 95px;
  padding: 6px 8px;
  outline: none;
}
