@use "src/styles/colors";
@use "src/styles/fonts";

.multi-input {
  @apply text-sm;
  display: flex;
  position: relative;
  border-radius: theme("spacing.5");
  color: colors.$grey8;
  box-shadow: 0 0 0 theme("spacing.1") theme("colors.light-blue-steel");
  cursor: pointer;
  padding: theme("spacing.6") theme("spacing.7");
  align-items: center;

  &:hover {
    box-shadow: 0 0 0 theme("spacing.2") theme("colors.light-teal");
  }

  &.active {
    outline: none;
    box-shadow: 0 0 0 2px theme("colors.light-teal"),
      0 0 0 5px rgba(#c5f1f1, 0.3);
    color: colors.$black;

    &::placeholder {
      opacity: 0;
    }
  }

  .placeholder {
    color: theme("colors.light-blue-steel");
    margin: theme("spacing.2") 0;
  }

  .arrow {
    @apply w-7 h-7;
    transition: transform 0.2s ease;
    pointer-events: none;
    margin-top: theme("spacing.3");

    &.active {
      transform: rotate(180deg);
    }
  }

  .record {
    @apply font-sm;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    background: theme("colors.mint");
    color: theme("colors.teal");
    border-radius: theme("spacing.5");
    margin-right: theme("spacing.4");
    margin-bottom: theme("spacing.4");
    padding: theme("spacing.2") theme("spacing.4");
    max-width: 100%;

    &-remove {
      display: flex;
      align-items: center;
      justify-content: center;
      width: theme("spacing.7");
      height: theme("spacing.7");
      margin-left: theme("spacing.4");
    }

    svg {
      stroke-width: 1.5;

      path {
        stroke: colors.$lavender6;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    background-color: colors.$grey2;

    .arrow {
      display: none;
    }

    .record {
      &-remove {
        display: none;
      }
    }
  }

  &.error {
    padding-bottom: theme("spacing.7");
    padding-top: theme("spacing.5");
    &,
    &:hover {
      @apply shadow-[0_0_0_2px] shadow-red/50;
    }

    &:focus {
      box-shadow: 0 0 0 theme("spacing.2") theme("colors.red"),
        0 0 0 5px colors.$red2;
    }

    .error-message {
      @apply text-xs text-red/50;
      position: absolute;
      bottom: 0;
    }
  }
}
