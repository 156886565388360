@use "src/styles/colors";
@use "src/styles/fonts";

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: theme("colors.blue-steel");

  &-links {
    span {
      @include fonts.small;
      letter-spacing: 2px;
    }
    &__item {
      @apply font-sm;
      border-radius: 5px;
      padding: 5px;
      min-width: 30px;
      min-height: 30px;
      text-align: center;
      margin: 0 3px;
      font-weight: fonts.$regular;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      svg {
        stroke-width: 1.5;
        width: 20px;
        height: 20px;
      }
      &:hover,
      &--active {
        background-color: theme("colors.blackberry");
        color: colors.$white;
        svg {
          path {
            @apply stroke-white;
          }
        }
      }
    }

    &-navigation {
      flex: 0 0 32px;
      display: flex;
    }
  }
}
