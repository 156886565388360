.search {
  width: 240px;
  padding: 0 20px;
  display: block;
  margin: theme("spacing.7") auto theme("spacing.5");

  input,
  div[class*="input__icon"] {
    height: 35px;

    svg {
      margin: 0;
    }
  }
}

.options {
  max-height: 300px;
}

.input {
  width: 100%;

  svg {
    path {
      stroke: theme("colors.blue-steel");
    }
  }
}
