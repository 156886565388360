.input {
  width: 466px;
  max-width: 74vw;
  position: relative;
}

.date-picker {
  svg {
    @apply mr-5;

    path {
      @apply stroke-blackberry;
    }
  }
}
