.container {
  @apply pt-8;

  width: 450px;

  .popover {
    width: 450px;
  }

  .search {
    margin: 10px 20px;
    width: calc(100% - 40px);
  }

  .fieldset {
    @apply mb-7 relative;

    .label {
      @apply block text-l font-bold mb-5 text-blackberry;
    }
  }
}