@use "src/styles/colors";
@use "src/styles/fonts";

.avatar {
  width: 50px;
  font-size: 12px;
  position: relative;
  padding: 3px;

  &--editable {
    label {
      cursor: pointer;
    }

    &:hover {
      font-size: 0;
    }
  }

  &--editable:hover &__placeholder {
    @apply bg-teal;

    &::after {
      transform: translate(-50%, -50%) scale(100%);
    }
  }

  &__upload {
    transition: opacity 0.1s ease-in;
    visibility: hidden;
    opacity: 0;
    padding-top: theme("spacing.3");

    label {
      @include fonts.little;
      color: colors.$grey7;
      text-align: center;
      display: block;
      &:hover {
        color: colors.$purple6;
      }
    }

    input {
      display: none;
    }

    &__error {
      margin-top: theme("spacing.4");
      line-height: 5px;
      @include fonts.little;
      color: theme("colors.red");
      text-align: center;
    }
  }

  &:hover &__upload {
    visibility: visible;
    opacity: 1;
  }

  &__placeholder {
    @apply bg-light-teal text-teal;
    align-items: center;
    transition: background-color, font-size 0.1s ease;
    border-radius: 50%;
    display: flex;
    width: 100%;
    justify-content: center;
    text-transform: capitalize;
    aspect-ratio: 1/1;
    position: relative;
  }

  &__loader {
    z-index: 2;
    top: 0;
    position: absolute;
    width: 100%;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__picture {
    border-radius: 50%;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    &--highlight {
      box-shadow: 0px 0px 0px 1px colors.$turquoise6,
        0px 0px 0px 3px colors.$turquoise2;
    }
  }
}
