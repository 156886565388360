.profile {
  @apply flex p-5 cursor-pointer items-center rounded-lg;
  transition: background-color 0.2s;

  &:hover {
    @apply bg-mint;
  }

  .avatar {
    @apply w-10 mr-6;
  }

  .details {
    @apply pr-3;
    flex: 1 1;
    overflow: hidden;

    .account-name {
      @apply text-sm text-blackberry font-bold truncate;
    }

    .user-name {
      @apply text-xs text-blue-steel font-medium truncate;
    }
  }
}

.popover {
  max-width: 200px;
  max-height: 600px;
  overflow: auto;

  .account-item {
    @apply flex items-center;

    &-active {
      &,
      &:hover {
        @apply bg-mint/[0.8] pointer-events-none;
      }
    }

    &-last {
      @apply border-b-[1px] border-light-blue-steel;
    }

    &-name {
      @apply flex-1 truncate ml-5 text-sm font-medium text-blackberry;
    }

    &-avatar {
      @apply w-9;
    }
  }

  .navigation {
    @apply flex items-center whitespace-nowrap font-medium py-7;

    svg {
      @apply mr-5 w-7 h-7;
      stroke-width: 1.5;
    }
  }
}
