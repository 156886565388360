.tool {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  border: 1px solid theme("colors.light-blue-steel");
  padding: 20px;
  transition: box-shadow 0.2s linear;

  &:hover {
    @apply shadow-[0_5px_20px_0] shadow-blackberry/10;
  }

  &.borderless {
    border: none;
    padding: 0;
  }

  &.disabled,
  &.borderless {
    &:hover {
      @apply shadow-none;
    }
  }

  .link {
    width: fit-content;
    margin-left: -20px;
  }

  .icon svg path {
    @apply stroke-blackberry;
    stroke-width: 1.5;
  }

  &.disabled {
    cursor: not-allowed;

    .link {
      &.disabled {
        background-color: transparent;
        border: none;
      }
    }
  }
}
