.container {
  @apply border border-solid border-light-blue-steel cursor-pointer relative;

  &.list {
    height: 73px;

    .description {
      @apply text-sm;
      margin-top: 5px;
    }
  }

  .description {
    @apply text-xs font-medium;
  }

  .label {
    @apply flex items-center gap-2 cursor-pointer rounded-full;

    svg {
      @apply w-7 h-7;

      path {
        @apply stroke-white;
        stroke-width: 1.5;
      }
    }
  }

  .placeholder-image {
    width: 46px;
    height: 45px;

    path {
      stroke: theme("colors.light-blue-steel");
      stroke-width: 1.5px;
    }
  }
}
