.fieldset {
  @apply flex flex-col mb-8 relative;

  .label {
    @apply text-l font-bold mb-6;
  }

  .dropdown {
    width: 100%;

    svg {
      path {
        stroke: theme("colors.blue-steel");
      }
    }
  }
}

.error {
  @apply mt-4;
}