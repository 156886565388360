@use "src/styles/colors";
@use "src/styles/fonts";

.activity-event {
  display: flex;
  word-break: break-word;

  .title {
    @apply text-base font-regular text-blackberry mb-3;
  }

  .info {
    @apply text-xs font-medium text-blue-steel;

    & > * {
      margin: 0 theme("spacing.5");

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .note {
    @apply text-sm font-regular text-mid-blue-steel mt-8;
    white-space: pre-line;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin-right: theme("spacing.6");
    flex-shrink: 0;

    svg {
      width: 16px;
      height: 16px;
      stroke-width: 1.5;
    }

    &--brand_profile_edited,
    &--brand_profile_created,
    &--org_credits_under_threshold,
    &--organization_account_manager_assigned,
    &--project_account_manager_assigned,
    &--subscription_plan_expired,
    &--credits_expire_alert,
    &--subscription_plan_renewed,
    &--custom_subscription_expire_alert,
    &--auto_renewal_alert,
    &--subscription_plan_edited,
    &--master_org_created,
    &--org_linked_to_master_org,
    &--org_unlinked_from_master_org,
    &--org_created,
    &--subscription_plan_name_edited,
    &--subscription_plan_credits_limit_edited,
    &--subscription_plan_status_edited,
    &--subscription_plan_date_edited,
    &--org_type_edited,
    &--credits_transferred_between_organizations,
    &--credits_assigned_from_master_organization_credit_balance {
      @apply bg-teal;

      path {
        @apply stroke-white;
      }
    }

    &--brief_comment_created,
    &--project_deleted,
    &--project_gallery_downloaded,
    &--project_feedback_created,
    &--project_feedback_edited {
      @apply bg-grey;

      svg path {
        @apply stroke-blackberry;
      }
    }

    &--project_partial_delivery,
    &--project_regressed_to_draft,
    &--project_canceled,
    &--project_moved_in_queue,
    &--project_approved,
    &--project_submitted,
    &--project_moved_to_under_revision,
    &--project_finalized,
    &--project_completed,
    &--project_moved_to_queue,
    &--brief_updated,
    &--project_clarification_requested,
    &--project_created,
    &--master_org_edited,
    &--user_added_to_org,
    &--user_removed_from_organization,
    &--user_added_to_master_organization,
    &--user_removed_from_master_organization {
      @apply bg-mint;

      svg path {
        @apply stroke-teal;
      }
    }

    &--asset_downloaded,
    &--asset_comment_created,
    &--asset_rating_edited,
    &--new_asset_version_created,
    &--asset_needs_changes,
    &--bulk_comments_created {
      @apply bg-lilac;

      svg path {
        @apply stroke-purple;
      }
    }

    &--org_credits_updated {
      background-color: colors.$peach1;
    }

    &.rotate {
      svg {
        transform: scaleX(-1) rotate(180deg);
      }
    }
  }

  .credit-details__difference {
    @include fonts.little;
    color: colors.$peach8;
    line-height: 45px;
    text-align: center;
  }
}

.avatar {
  padding: 0;
  width: 45px;
}
