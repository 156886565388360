.fieldset {
  @apply flex flex-col;
}

.name {
  @apply w-full;
}

.role-warning {
  @apply text-blackberry text-center mt-7;
}
