@use "src/styles/colors";
@use "src/styles/fonts";

$previewSize: 105px;

.note {
  border-radius: 0;
  box-shadow: none;
  position: relative;

  & > section {
    position: relative;
  }

  &.hasThread {
    & > section {
      &::before {
        content: "";
        height: 100%;
        background-color: theme("colors.light-blue-steel");
        position: absolute;
        width: 1px;
        left: 22px;
        z-index: 1;
      }
    }
  }

  .replies {
    .details {
      @apply mr-4;
    }
  }

  &.active {
    @apply bg-mint;
  }

  &.hasBorder {
    border-bottom: 1px solid colors.$grey5;
    padding-top: theme("spacing.8");
    padding-bottom: theme("spacing.8");
  }

  &.hasPreview {
    min-height: calc($previewSize + theme("spacing.8") * 2);
    padding-right: $previewSize;

    .details {
      margin-right: $previewSize;
      margin-top: 0;
    }

    .edit-form {
      margin-right: theme("spacing.6");
    }

    .replies {
      .details {
        margin: 0;
      }
    }
  }

  &#{&} section {
    padding: 0;
    overflow: initial;
  }
}

.avatar {
  margin-right: theme("spacing.5");
  min-width: 45px;
  width: 45px;
  z-index: 2;
}

.region {
  @mixin region {
    position: absolute;
    background-color: theme("colors.teal");
    top: calc(-1 * theme("spacing.4"));
    left: calc(-1 * theme("spacing.4"));
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  &-number {
    @include region;
    @apply text-xs font-bold;
    color: colors.$white;
    padding: theme("spacing.2") theme("spacing.5");
    aspect-ratio: 1/1;
    width: max-content;
  }

  &-time {
    @apply text-teal text-xs font-medium mt-4;
  }

  &-section {
    @include region;
    padding: theme("spacing.3");

    svg {
      @apply w-7 h-7;
      path {
        @apply stroke-white;
        stroke-width: 1.5;
      }
    }
  }
}

.title {
  align-items: flex-start;
  display: flex;
  margin-bottom: theme("spacing.4");

  &__editable {
    margin-right: theme("spacing.8");
  }

  p {
    @apply text-sm font-bold text-blackberry;
  }

  small {
    @apply text-light-blue-steel mx-3;
  }

  span {
    @apply text-xs font-medium text-blue-steel;
  }
}

.description {
  @include fonts.small;
  flex-direction: column;
  color: colors.$black9;
  display: flex;
  word-break: break-word;

  .edit-form {
    margin-top: theme("spacing.5");
  }
}

.preview {
  height: $previewSize;
  position: absolute;
  right: -$previewSize;
  top: 0;
  width: $previewSize;
  z-index: 2;
}

.details {
  display: flex;
  flex-direction: column;
  margin-right: theme("spacing.5");
  margin-top: calc(theme("spacing.6") * -1);
  opacity: 0;

  .button {
    background-color: colors.$white;
    margin-bottom: theme("spacing.4");

    img {
      max-width: none;
    }

    svg {
      path {
        stroke: theme("colors.blue-steel");
      }
    }
  }
}

.textarea {
  display: flex;
  flex-direction: column;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  overflow: visible;
  position: relative;

  &.create {
    .textarea-container,
    > textarea {
      height: 215px;
    }

    &.expanded,
    &.fullfilled {
      .textarea-container,
      > textarea {
        height: 173px;
      }
    }
  }

  &.compact:not(.expanded) {
    .textarea-container,
    > textarea {
      height: 42px;
      min-height: auto;

      .textarea-input {
        min-height: auto;
      }
    }
  }

  &.change {
    min-width: 285px;

    div[class*="controls"] {
      display: flex;
      justify-content: space-between;

      & > button {
        flex: 1;
      }
    }
  }
}

.dragPreview {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: theme("colors.mint");
  border: 1px dashed theme("colors.teal");
  z-index: 1;
  pointer-events: none;

  svg {
    width: 45px;
    height: 45px;

    path {
      @apply stroke-teal;
    }
  }
}

.textarea-input {
  padding: 10px 20px !important;
  overflow-y: auto !important;
}
