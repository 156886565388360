@use "src/styles/fonts";
@use "src/styles/colors";

$footerHeight: 85px;

.footer {
  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 83px 0 33px;
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: $footerHeight;
    background-color: colors.$white;
    box-shadow: 0px 9px 28px 8px rgba(colors.$purple6, 0.05),
      0px 6px 16px rgba(colors.$purple6, 0.08),
      0px 3px 6px -4px rgba(colors.$purple6, 0.08);
  }

  .margin {
    height: $footerHeight;
  }
}
