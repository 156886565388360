@use "src/styles/fonts";
@use "src/styles/colors";

.avatar {
  width: 80px;
  margin-right: theme("spacing.7");
}

.name {
  @apply text-l text-blackberry font-bold;
}

.time {
  @apply text-xs font-medium text-mid-blue-steel mb-4;
}

.asset {
  width: 182px;
  height: 182px;
  border-radius: 28px;
  object-fit: cover;
}
