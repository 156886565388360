@use "src/styles/colors";
@use "src/styles/fonts";

.upload-media {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  border: 1px solid theme("colors.light-blue-steel");
  transition: all 0.2s ease;

  &.drag-over:not(.disabled) {
    background-color: theme("colors.mint");
    border: 1px dashed theme("colors.teal");

    p {
      display: none;
    }

    svg path {
      stroke: theme("colors.teal");
    }

    * {
      pointer-events: none;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  &--regular {
    height: 384px;
  }

  &--small {
    height: 244px;
  }

  &--tiny {
    padding: theme("spacing.8");
    max-height: 186px;

    .title[class] {
      text-align: center;
      margin-top: theme("spacing.6");
    }

    .types {
      display: none;
    }
  }

  .title {
    @apply text-base font-medium text-blue-steel mt-7;

    span {
      @apply text-teal;
    }
  }

  .types {
    @apply text-sm text-blue-steel;
    max-width: 323px;
    text-align: center;
    margin-top: theme("spacing.4");

    span:not(:last-child):after {
      content: ", ";
    }
  }

  &.cover {
    position: relative;
    cursor: initial;
    padding: 0;

    &:hover {
      .closeButton {
        opacity: 1;
      }
    }

    img,
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }

    .closeButton {
      opacity: 0;
      position: absolute;
      outline: none;
      top: theme("spacing.7");
      right: theme("spacing.7");
      path {
        @apply stroke-red;
        stroke-width: 1.5;
      }
    }
  }

  input {
    display: none;
  }
}
