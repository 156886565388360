.popover {
  width: calc(100% - 71px);
}

.delete {
  @apply p-5;

  &:hover {
    svg {
      path {
        stroke: theme("colors.red");
      }
    }
  }
}

.row {
  &:hover {
    background-color: transparent !important;
  }
}
