.container {
  @apply w-full flex-1;

  &.regular {
    .title {
      @apply text-base placeholder:text-base;
    }
  }

  &.small {
    .title {
      @apply text-sm placeholder:text-sm;
    }
  }

  .title {
    @apply font-bold text-blackberry truncate;
    word-break: break-word;
  }
}
