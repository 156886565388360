.type {
  @apply flex items-center gap-4 px-6 cursor-pointer;

  svg {
    @apply w-7 h-7;

    path {
      @apply stroke-white;
      stroke-width: 1.5;
    }
  }

  &.active {
    background-color: theme("colors.teal");
  }
}
