@use "src/styles/fonts";
@use "src/styles/colors";

.create-account {
  @apply text-blackberry;
  max-width: 750px;

  .section {
    @apply grid grid-flow-col gap-10;
    grid-template-columns: 120px 510px;
  }

  .fieldset {
    @apply flex flex-col mb-8 relative;

    .label {
      @apply text-l font-bold mb-6;
    }

    .dropdown {
      width: 100%;

      svg {
        path {
          stroke: theme("colors.blue-steel");
        }
      }
    }
  }

  .error {
    @apply mt-4;
  }
}
